import * as React from "react";
import { FC } from "react";
import { FancyLine } from "../common/components/lines/fancy-line";
import {
  Heading,
  HEADING_CLASS,
  HEADING_LEVEL
} from "../common/components/typography/heading";
import { useLocalized } from "../common/localization/localize";
import { ILocalizedPage } from "../common/types/page";
import { Address } from "../components/contact/address";
import { Contact } from "../components/contact/contact";
import { Disclaimer } from "../components/contact/disclaimer";
import { Module } from "../components/contact/module";
import { Support } from "../components/contact/support";
import { Wrapper } from "../components/contact/wrapper";

export const ContactEntrypoint: FC<ILocalizedPage> = ({ lang: _lang }) => {
  const heading = useLocalized("contact_heading");
  return (
    <>
      <Heading level={HEADING_LEVEL.H1} theme={HEADING_CLASS.MAIN}>
        {heading}
      </Heading>
      <FancyLine />
      <Wrapper>
        <Module>
          <Address />
        </Module>
        <Module>
          <Contact />
        </Module>
        <Module>
          <Support />
        </Module>
        <Module>
          <Disclaimer />
        </Module>
      </Wrapper>
    </>
  );
};
