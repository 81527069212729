import * as React from "react";
import { FC } from "react";

export const Contact: FC = () => (
  <div>
    <div>
      Telefon: <a href="tel:+41448306477">+41 (0)44 830 64 77</a>
    </div>
    <div>
      Email:{" "}
      <a href="mailto:norbert-jan@paprotny-kunstmaler.ch">
        {"norbert-jan{at}paprotny-kunstmaler{punkt}ch"}
      </a>{" "}
      *
    </div>
  </div>
);
