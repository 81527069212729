import * as React from "react";
import { FC } from "react";
import { useLocalized } from "../../common/localization/localize";

export const Support: FC = () => {
  const part1 = useLocalized("contact_support_part1");
  const part2 = useLocalized("contact_support_part2");
  const part3 = useLocalized("contact_support_part3");
  return (
    <p>
      {part1} <a href={"https://www.gatsbyjs.org/"}>Gatsby</a> {part2}
      <br />
      {part3}
      <br />
      <a href="mailto:support@paprotny-kunstmaler.ch">
        {"support{at}paprotny-kunstmaler{punkt}ch"}
      </a>{" "}
      *
    </p>
  );
};
