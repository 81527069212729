import * as React from "react";
import { FC } from "react";

export const Address: FC = () => (
  <>
    <div>Norbert Jan Paprotny</div>
    <div>Konservenstrasse 60</div>
    <div>CH-8215 Hallau</div>
  </>
);
