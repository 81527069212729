import * as React from "react";
import { FC } from "react";
import { useLocalized } from "../../common/localization/localize";

export const Disclaimer: FC = () => {
  const line1 = useLocalized("contact_email_disclaimer_line1");
  const line2 = useLocalized("contact_email_disclaimer_line2");
  return (
    <p>
      * {line1}
      <br />
      {line2}
    </p>
  );
};
